//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SocialMediaList from '../SocialMediaList.vue';
import LanguageSwitch from '../LanguageSwitch.vue';
import FooterNavigationMeta from './FooterNavigationMeta.vue';
import FooterLegal from './FooterLegal.vue';
import FooterNavigationMain from './FooterNavigationMain.vue';
import FooterTopButton from './FooterTopButton.vue';

export default {
    name: 'FooterComponent',
    components: {
        FooterTopButton,
        FooterNavigationMain,
        FooterLegal,
        FooterNavigationMeta,
        LanguageSwitch,
        SocialMediaList,
    },
    data() {
        return {
            debounce: 0,
        };
    },
    mounted() {
        window.addEventListener('resize', this.setFooterHeightProperty, { passive: true });
        this.setFooterHeightProperty();
    },
    methods: {
        setFooterHeightProperty() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                if (this.$refs.footer) {
                    const root = document.documentElement;
                    root.style.setProperty('--footer-height', `${this.$refs.footer.getBoundingClientRect().height}px`);
                }
            }, 200);
        },
    },
};
