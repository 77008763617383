//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PRODUCT_CATEGORY_TYPE_EQUIPMENT, PRODUCT_CATEGORY_TYPE_BATHTUB, PRODUCT_CATEGORY_TYPE_FURNITURE, WATCHABLE, SHOWPRICES } from '../../../constants/index';
import { mapGetters } from 'vuex';

export default {
    name: 'ProductTeaser',
    props: [
        'product',
        'extras',
        'variants',
        'useVariant',
        'hidePrice',
        'url',
        'isWatchList',
        'watchList',
        'isConfiguratorList',
        'configuratorLink',
        'disableLink',
        'configurable',
        'configuratorSet',
        'hideFooter',
        'productRange',
        'showSpecialTeaser',
        'merchantList',
    ],
    mixins: [require('../../../mixins/product_range.js').default, require('../../../mixins/pricing.js').default],
    data() {
        return {
            variant: null,
            keywords: null,
            text: null,
            type: null,
            tax: null,
            addedPrice: 0,
            showToolTip: false,
            specialTeaser: null,

            FURNITURE: PRODUCT_CATEGORY_TYPE_FURNITURE,
            EQUIPMENT: PRODUCT_CATEGORY_TYPE_EQUIPMENT,
            BATHTUB: PRODUCT_CATEGORY_TYPE_BATHTUB,
            WATCHABLE: WATCHABLE,
            SHOWPRICES: SHOWPRICES,
        };
    },
    watch: {
        specialTeaser() {
            if (this.specialTeaser) {
                this.$emit('specialTeaser', this.product.id);
            }
        },
    },
    computed: {
        ...mapGetters(['getStoredSiteProperties']),
        isInSelectedRange() {
            if (!this.productRange) return true;
            return this.productRange.toUpperCase() === this.getSelectedProductRange();
        },
        priceSuffix() {
            return this.$t('product.info.price.suffix');
        },
        rangeCountry() {
            return this.productRange ? this.$t(`country.${this.productRange}`) : null;
        },
        priceWithTax() {
            let bestPrice;
            if (this.variant) {
                bestPrice = this.getVariantPrice(this.variant, this.product.colorClass, this.productRange);
            } else {
                bestPrice = this.getBestPrice();
            }

            if (bestPrice) {
                const format = this.$i18n.locale;
                const isGBP = bestPrice.currency === '£';

                if (this.addedPrice > 0) {
                    const price = ((bestPrice.price + this.addedPrice) / 100) * (100 + this.tax);

                    if (isGBP) {
                        // Für GBP: Symbol vorne, aber deutsche Zahlenformatierung
                        return `£${price.toLocaleString(format, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true
                        })}`;
                    }

                    // Für EUR: Normale Locale-Formatierung
                    return price.toLocaleString(format, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR'
                    });
                }

                const price = (bestPrice.price / 100) * (100 + this.tax);

                if (isGBP) {
                    // Für GBP: Symbol vorne, aber deutsche Zahlenformatierung
                    return `£${price.toLocaleString(format, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true
                    })}`;
                }

                // Für EUR: Normale Locale-Formatierung
                return price.toLocaleString(format, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR'
                });
            }
            return null;
        },
        isTouchScreen() {
            return this.isConfiguratorList || (this.$store.getters.getStoredPage && this.$store.getters.getStoredPage.properties.find((p) => p.name === 'touch_screen')?.checked === true);
        },
        image() {
            const images = this.product?.images;
            let defaultImage = null;
            if (this.extras && images?.[0]?.teaser) return this.$mapImageData(images?.[0]?.teaser);
            if (this.variants && images?.[0]?.gallery) return this.$mapImageData(images?.[0]?.gallery);

            defaultImage = images?.filter((image) => image?.assetKey === 'A_PIM_Bild')[0]?.teaser;

            if (defaultImage) {
                defaultImage = this.$mapImageData(defaultImage);
            } else if (!defaultImage) {
                defaultImage = this.$mapImageData(images?.[0]?.gallery);
            }

            return defaultImage;
        },
        href() {
            if (!this.isConfiguratorList && this.isTouchScreen) {
                const pageId = this.$store.getters.getStoredPage.properties.find((p) => p.name === 'configurator_touch_page')?.object?.id;
                return pageId ? this.$localeSlug(this.$findMenuItemById(pageId, this.$store.state.menu[this.$i18n.locale])) + `?product=${this.product?.id}` : null;
            } else if (this.isConfiguratorList) {
                if (this.configuratorLink) {
                    return `${this.configuratorLink}?product=${this.product?.id}`;
                } else {
                    const pageId = this.$store.getters.getStoredPage.properties.find((p) => p.name === 'configurator_page')?.object?.id;
                    return pageId ? this.$localeSlug(this.$findMenuItemById(pageId, this.$store.state.menu[this.$i18n.locale])) + `?product=${this.product?.id}` : null;
                }
            }

            if (this.url) {
                return this.url;
            }
            let href = this.$localeSlug(this.getStoredSiteProperties.product_page);
            href = href + '/' + this.product.slug;

            return href;
        },
        isSpecialTeaser() {
            return this.showSpecialTeaser === true && this.product.hasLandingPage === true;
        },
        headline() {
            return this.product?.name || 'Unnamed product';
        },
        isConfigurable() {
            return this.configurable !== false && this.product.variants && this.product.variants.find((v) => ['DW', 'BW', 'WT', 'ALAPE'].includes(v.kMat));
        },
        isInWatchList() {
            return this.$store.getters.getWatchList.find((o) => o.id === this.variant.id);
        },
    },
    created() {
        const product = this.product;
        if (product.configExtras) {
            this.getOptionsPrices();
        }

        this.type = PRODUCT_CATEGORY_TYPE_BATHTUB;
        this.keywords = this.getKeywords(product);
        this.text = this.getText(product);

        if (this.isWatchList || this.useVariant) {
            this.variant = product.variants[0];
        }
        if (this.configuratorSet) {
            this.variant = product.variants.filter((variant) => variant.id == product.variantId)[0];
        }
    },
    methods: {
        toggleToolTip() {
            if (this.isInSelectedRange) return;
            this.showToolTip = !this.showToolTip;
        },
        getOptionsPrices() {
            for (let [key, value] of Object.entries(this.product.configExtras)) {
                if (value.prices) {
                    let optionPrice = this.getVariantPrice(value, null, this.productRange);
                    this.addedPrice += optionPrice?.price ?? 0;
                }
            }
        },
        toggleWatchList() {
            if (this.isInWatchList) {
                this.$emit('removeFromWatchList', [this.product, this.variant]);
                this.$store.dispatch('removeFromWatchList', {
                    object: this.variant,
                });
            } else {
                this.$emit('addToWatchList', [this.product, this.variant]);
                this.$store.dispatch('addToWatchList', {
                    object: this.variant,
                });
            }
        },
        /**
         * Returns keyword list comma separated
         * @param product
         * @returns {string}
         */
        getText(product) {
            if (product.variants) {
                this.$getProductColorAmount(product);
            }
            let text = null;

            if (this.type === this.EQUIPMENT && product.variants) {
                text = `
                        ${this.$t('product.teaser.articlenumber')}: <nobr>${this.product.variants[0].externalKey}</nobr><br/>
                        ${this.$t('product.teaser.dimensions.plural')}: <nobr>${this.product.variants[0].width} x ${this.product.variants[0].height} x ${this.product.variants[0].length} ${this.$t('dimensions.units')}</nobr>
                    `;
            }

            if (this.extra) {
                text = `
                        ${this.product.headline}
                    `;
            }

            return text;
        },
        /**
         * Returns keyword list comma separated
         * @param product
         * @returns {string}
         */
        getKeywords(product) {
            let keywords = [];
            let keywordsString = '';
            ['installationType', 'shape', 'functions', 'accessoryCategory', 'suitableFor'].forEach((key) => {
                if (product[key]) {
                    keywords.push(product[key].split(','));
                }
                keywords = [...new Set(keywords.flat(2))];
            });

            keywordsString = keywords.join(', ');

            if (this.extras) {
                keywordsString = `${this.$t('productteaser.category.main')}, ${keywordsString}`;
            }

            return keywordsString;
        },
    },
    async fetch() {
        const currentProductRangeCountry = this.productRange?.toLowerCase() ?? this.getSelectedProductRange().toLowerCase();
        this.tax = await this.$nuxt.context.app.api.product.getTax(currentProductRangeCountry);
        if (this.isSpecialTeaser && this.product.hasLandingPage && !this.isConfiguratorList) {
            const teaser = await this.$nuxt.context.app.api.product.getLandingPage(this.$i18n.locale, this.product.id);
            if (teaser?.showTeaser) {
                this.specialTeaser = teaser;
            }
        }
    },
};
