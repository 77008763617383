import Vue from 'vue';
import MissingComponent from './Debug/MissingComponent';

const componentMap = {
    object_ScrollSequence: () => import('./Content/ScrollSequence.vue'),
    object_ConfiguratorSet: () => import('./Content/ConfiguratorSet.vue'),
    object_ConfiguratorProducts: () => import('./Configurator/ConfiguratorProducts.vue'),
    object_Graph: () => import('./Content/Graph.vue'),
    object_EventList: () => import('./Content/EventList.vue'),
    object_AnimationVideo: () => import('./Content/AnimationVideo.vue'),
    object_Anchor: () => import('./Content/Anchor.vue'),
    object_CrossSectionDrawing: () => import('./Content/CrossSectionDrawing.vue'),
    object_AnchorNav: () => import('./Content/AnchorNav.vue'),
    object_Accordion: () => import('./Content/Accordion.vue'),
    object_Tab: () => import('./Content/Tab.vue'),
    object_ProductTeaserSelection: () => import('./Content/ProductTeaserSelection.vue'),
    object_ColorOverview: () => import('./Content/ColorOverview.vue'),
    object_ContentTeaser: () => import('./Content/ContentList.vue'),
    object_CtaTeaser: () => import('./Content/CtaTeaser.vue'),
    object_DealerLocatorTeaser: () => import('./Content/DealerLocatorTeaser.vue'),
    object_Downloads: () => import('./Content/Downloads.vue'),
    object_ProductData: () => import('./Content/ProductData.vue'),
    object_OXOMI: () => import('./Content/OXOMI.vue'),
    object_FormContactContainer: () => import('./Form/FormContactContainer.vue'),
    object_FormNewsletterContainer: () => import('./Form/FormNewsletterContainer.vue'),
    object_IconTeaser: () => import('./Content/IconTeaser.vue'),
    object_Iframe: () => import('./Content/Iframe.vue'),
    object_ImageLarge: () => import('./Content/ImageLarge.vue'),
    object_ImageOffset: () => import('./Content/ImageOffset.vue'),
    object_ImageSelectionGallery: () => import('./Content/ImageSelectionGallery.vue'),
    object_ImageTeaser: () => import('./Content/ImageTeaser.vue'),
    object_ImageTwoCol: () => import('./Content/ImageTwoCol.vue'),
    object_LinklistTeaser: () => import('./Content/LinklistTeaser.vue'),
    object_MerchantSearch: () => import('./MerchantSearch.vue'),
    object_NewsList: () => import('./Partials/News/NewsList.vue'),
    object_PressReleaseList: () => import('./Partials/PressRelease/PressReleaseList.vue'),
    object_ProductAccessTeaser: () => import('./Content/ProductAccessTeaser.vue'),
    object_ProductList: () => import('./Product/ProductList.vue'),
    object_Quote: () => import('./Content/Quote.vue'),
    object_ReferenceList: () => import('./Partials/Reference/ReferenceList.vue'),
    object_ReferenceTeaserSlider: () => import('./Partials/Reference/ReferenceTeaserSlider.vue'),
    object_SearchResult: () => import('./SearchResult.vue'),
    object_Stage: () => import('./Content/Stage.vue'),
    object_StageContent: () => import('./Content/StageContent.vue'),
    object_StageHomepage: () => import('./Content/StageHomepage.vue'),
    object_TextCentered: () => import('./Content/TextCentered.vue'),
    object_TextColumns: () => import('./Content/ContentList.vue'),
    object_TextIcons: () => import('./Content/TextIcons.vue'),
    object_TextImage: () => import('./Content/TextImage.vue'),
    object_TextImageIntro: () => import('./Content/TextImageIntro.vue'),
    object_TextImageMultiImage: () => import('./Content/TextImageMultiImage.vue'),
    object_TextImageOffset: () => import('./Content/TextImageOffset.vue'),
    object_TextVideo: () => import('./Content/TextVideo.vue'),
    object_TwoImageTeaser: () => import('./Content/TwoImageTeaser.vue'),
    object_VideoLarge: () => import('./Content/VideoLarge.vue'),
    object_VideoTeaser: () => import('./Content/VideoTeaser.vue'),
    object_WatchList: () => import('./Content/WatchList.vue'),
    object_Configurator: () => import('./Configurator/Configurator.vue'),
    object_ShowerNavigator: () => import('./ShowerNavigator/ShowerNavigator.vue'),
    object_FormLoginContainer: () => import('./Form/FormLoginContainer.vue'),
    object_FormRegisterContainer: () => import('./Form/FormRegisterContainer.vue'),
    object_ProductTeaserSlider: () => import('./Content/ProductTeaserSlider.vue'),
    object_UserPage: () => import('./Content/UserPage.vue'),
    object_LargeFact: () => import('./Content/LargeFact.vue'),
    object_SmallFacts: () => import('./Content/SmallFacts.vue'),
    object_SmallFactsImage: () => import('./Content/SmallFactsImage.vue'),
    object_LinkListLarge: () => import('./Content/LinkListLarge.vue'),
    object_ImageGallerySlider: () => import('./Content/ImageGallerySlider.vue'),
    object_CtaTeaserLead: () => import('./Content/CtaTeaserLead.vue'),
    object_LandingStage: () => import('./Content/LandingStage.vue'),
    object_SubNavi: () => import('./Content/SubNavi.vue'),
    object_ContactPersonSearch: () => import('./Content/ContactPersonSearch.vue'),
};

export default Vue.component('PageElement', {
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    render: function(createElement) {
        const component = this.element.contentList
            ? () => import('./Content/ContentList.vue')
            : componentMap[this.element.__typename];

        if (component) {
            return createElement(component, { props: { data: this.element } });
        }

        return process.env.NODE_ENV === 'development'
            ? createElement(MissingComponent, {
                  props: { typename: this.element.__typename },
              })
            : null;
    },
});
